Vue.component('bookmarks', {
    props: {
        bookmarks: Array,
        showBookmarksModal: Boolean,
    },
    computed: {
        bookmarksArray() {
            return this.$store.getters['Layout/bookmarks']
        },
        bookmarksAmount() {
            return this.bookmarksArray.length;
        },
    },
    methods: {
        isBookmarked(slideID) {
            return this.bookmarksArray.findIndex(item => item.id === slideID) !== -1;
        },
        toggleBookmark(bookmark, url) {
            this.$store.dispatch("Layout/toggleBookmark", {
                bookmark: bookmark,
                url: url,
            });
        },
        openBookmarksModal() {
            this.$store.dispatch("Layout/setBookmarkPrettyTitles");
            this.$emit('update:showBookmarksModal', true);
        },
        closeBookmarksModal() {
            this.$emit('update:showBookmarksModal', false);
        },
    },
    mounted: function() {
        this.$store.dispatch("Layout/setBookmarks", this.bookmarks);
        this.$store.dispatch("Layout/setBookmarkPrettyTitles");
    },
});