Layout.store.actions = {
    changeMenuExpanded({commit}, value) {
        commit('setMenuExpanded', value)
    },
    toggleBookmark({commit, state}, payload) {
        store.commit("Layout/toggleBookmark", payload.bookmark);

        Layout.api.post(payload.url, {bookmarks: state.bookmarks.map(a => a.id)});
    },
    setBookmarks({commit}, value) {
        store.commit("Layout/setBookmarks", value);
    },
    setBookmarkPrettyTitles({commit}, value) {
        store.commit("Layout/setBookmarkPrettyTitles");
    },
};