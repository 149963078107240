Layout.store.mutations = {
    setMenuExpanded(state, value) {
        state.menuExpanded = value;
    },
    setBookmarks(state, bookmarks) {
        state.bookmarks = bookmarks;
    },
    setBookmarkPrettyTitles(state) {
        state.bookmarks.forEach(function(bookmark, index) {
            Vue.set(state.bookmarks[index], 'prettyTitle', state.bookmarks[index].title.replace(/>/g, '<span class="navigation-section-index-list-item__seperator"></span>'));
        });
    },
    toggleBookmark(state, bookmark) {
        const index = state.bookmarks.findIndex(item => item.id == bookmark.id);
        if (index >= 0) {
            state.bookmarks.splice(index, 1);
        } else {
            state.bookmarks.push(bookmark);
        }

        // order bookmarks
        state.bookmarks = state.bookmarks.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    },
};