Vue.component('video-slide', {
    store,
    data() {
        return {
            focus: false,
            video: {},
        }
    },
    computed: {},
    methods: {
        initiateVideo: function () {
            let that = this;

            this.video.instance = videojs('#video-element', {
                controls: true,
                fluid: true,
                aspectRatio: '16:9',
                techOrder: ["html5", "Youtube"],
            });

            videojs('#video-element').ready(function() {
                that.video.instance.play();
                that.video.instance.controls(true);
                that.video.playing = true;
            });


            Barba.Dispatcher.on("linkClicked", function () {
                setTimeout(() => {
                    that.video.instance.dispose();
                }, 100);
            });
        },
    },
    mounted() {
        let that = this;
        let timeout;

        timeout = setTimeout(function() {
            that.focus = true;
        }, 3000);

        document.addEventListener("mousemove", function() {
            giveFocus();
        });

        document.addEventListener("touchstart", function() {
            giveFocus();
        });

        function giveFocus() {
            clearTimeout(timeout);
            that.focus = false;

            timeout = setTimeout(function() {
                that.focus = true;
            }, 3000);
        };

        this.initiateVideo();
    },
});