Vue.component('offices-slide', {
    store,
    data: function () {
        return {
            slider: null,
            markers: null,
            mapWidth: null,
            mapHeight: null
        }
    },
    computed: {},
    mounted: function () {
        this.setMapDimensions();
        this.placeMarkers();
    },
    methods: {
        convertGeoToPixel: function (latitude, longitude) {
            // X and Y boundaries
            const northLat = 53.555853;
            const southLat = 50.746063;

            const mapLonLeft = 3.359421;
            const mapLonRight = 7.220930;
            let mapLonDelta = mapLonRight - mapLonLeft;

            let mapLatBottom = 50.746063;
            let mapLatBottomDegree = mapLatBottom * Math.PI / 180;

            let x = (longitude - mapLonLeft) * (this.mapWidth / mapLonDelta);

            latitude = latitude * Math.PI / 180;
            let worldMapWidth = ((this.mapWidth / mapLonDelta) * 360) / (2 * Math.PI);
            let mapOffsetY = (worldMapWidth / 2 * Math.log((1 + Math.sin(mapLatBottomDegree)) / (1 - Math.sin(mapLatBottomDegree))));
            let y = this.mapHeight - ((worldMapWidth / 2 * Math.log((1 + Math.sin(latitude)) / (1 - Math.sin(latitude)))) - mapOffsetY);

            return {"x": x, "y": y};
        },
        placeMarkers: function () {
            let markerElems = document.querySelectorAll('.officesslide__marker');
            let that = this;

            var int = setInterval(function () {
                markerElems.forEach(function (marker) {
                    let mapCoords = that.convertGeoToPixel(marker.dataset.x, marker.dataset.y);

                    let leftPercentage = (mapCoords.x / that.mapWidth) * 100;
                    let topPercentage = (mapCoords.y / that.mapHeight) * 100;

                    marker.style.left = leftPercentage + "%";
                    marker.style.top = (topPercentage - 2) + "%";
                    marker.style.opacity = 1;
                });

            }, 500);

            setTimeout(function () {
                clearInterval(int)
            }, 2000);
        },
        setMapDimensions: function () {
            this.mapWidth = document.querySelector('.officesslide__inner-map img').clientWidth;
            this.mapHeight = document.querySelector('.officesslide__inner-map img').clientHeight;
        },
        hoverOverOfficeMarker: function (event) {
            let officeName = event.currentTarget.dataset.office;
            console.log('.office-item--' + officeName);
            document.querySelector('.office-item--' + officeName).classList.add('office-item--active');
        },
        hoverOutOfficeMarker: function (event) {
            let officeName = event.currentTarget.dataset.office;
            document.querySelector('.office-item--' + officeName).classList.remove('office-item--active');
        },
        hoverOverOfficeBlock: function (event) {
            let officeName = event.currentTarget.dataset.office;
            document.querySelector('.officesslide__marker--' + officeName).classList.add('officesslide__marker--active');
        },
        hoverOutOfficeBlock: function (event) {
            let officeName = event.currentTarget.dataset.office;
            document.querySelector('.officesslide__marker--' + officeName).classList.remove('officesslide__marker--active');
        }
    },
    created: function () {
    },
    destroyed: function () {
    }
});