Vue.component('app-header', {
    store,
    data: function () {
        return {
            scrolled: false,
            visible: true,
            showBookmarksModal: false,
            showPersonModal: false,
            openedChapter: false,
        }
    },
    props: {
        browsingSlides: Array,
        searchQuery: String
    },
    watch: {
        searchQuery: function() {
            if (this.openedChapter === true) {
                this.openedChapter = false;
            }
            else {
                this.$emit('update:browsingSlides', []);
            }
        }
    },
    computed: {
        menuExpanded: {
            get() {
                return this.$store.state.Layout.menuExpanded;
            },
            set(value) {
                this.$store.dispatch('Layout/changeMenuExpanded', value)
            }
        },
        bookmarks() {
            return this.$store.getters['Layout/bookmarks']
        },
    },
    mounted: function () {
        let that = this;
        let timeout;

        timeout = setTimeout(function() {
            that.visible = false;
        }, 3000);

        document.addEventListener("mousemove", function() {
            showMe();
        });

        document.addEventListener("touchstart", function() {
            showMe();
        });

        function showMe() {
            clearTimeout(timeout);
            that.visible = true;

            if(that.menuExpanded === false) {
                timeout = setTimeout(function () {
                    that.visible = false;
                }, 3000);
            }
        }
    },
    methods: {
        isBookmarked(slideID) {
            return this.bookmarks.findIndex(item => item.id === slideID) !== -1;
        },
        toggleBookmark(bookmark, url) {
            this.$store.dispatch("Layout/toggleBookmark", {
                bookmark: bookmark,
                url: url,
            });
        },
        browseChapter(chapterID) {
            this.openedChapter = true;
            this.$emit('update:searchQuery', '');
            this.browsingSlides.splice(0, this.browsingSlides.length);
            this.browsingSlides.push({level: 1, slideID: chapterID});
        },
        chapterClass(chapterID) {
            let result = [];

            for(let i = 0; i < this.browsingSlides.length; i++) {
                if (this.browsingSlides[i].slideID === chapterID) result.push('navigation-sections-list-item--active');
            }

            return result;
        },
        openPersonModal() {
            this.showPersonModal = true;
        },
        closePersonModal() {
            this.showPersonModal = false;
        }
    },
    created: function () {},
    destroyed: function () {}
});