Vue.component('app-footer', {
    store,
    data: function () {
        return {
            visible: true
        }
    },
    computed: {},
    methods: {},
    mounted: function (){
        let that = this;
        let timeout;

        timeout = setTimeout(function() {
            that.visible = false;
        }, 3000);

        document.addEventListener("mousemove", function() {
            showMe();
        });

        document.addEventListener("touchstart", function() {
            showMe();
        });

        function showMe() {
            clearTimeout(timeout);
            that.visible = true;

            timeout = setTimeout(function() {
                that.visible = false;
            }, 3000);
        }
    },
    created: function () {
    },
    destroyed: function () {
    }
});