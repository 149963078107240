Vue.component('specialism-form', {
    data() {
        return {
            specialismsArray: [""],
            specialismsUpdated: false,
        }
    },
    props: {
        specialisms: String
    },
    methods: {
        updateSpecialisms: function(fieldValue, index) {
            this.specialismsUpdated = true;
            this.specialismsArray[index] = fieldValue;
        },
        addSpecialism: function(index) {
            this.specialismsUpdated = true;
            this.specialismsArray.splice(index + 1, 0, '');
        },
        deleteSpecialism: function(index) {
            this.specialismsUpdated = true;

            if (this.specialismsArray.length > 1) {
                this.specialismsArray.splice(index, 1);
            }
            else {
                this.specialismsArray = [""];
            }
        }
    },
    mounted() {
        if(typeof this.specialisms !== 'undefined' && this.specialisms !== "") {
            let specialismsArray = JSON.parse(this.specialisms);

            if (specialismsArray.length >= 1) {
                this.specialismsArray = specialismsArray;
            }
        }
    }
});