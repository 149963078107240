Vue.component('pie-chart-slide', {
    store,
    data: function () {
        return {
            pieChartData: null,
            pieChartDataLabels: [],
            pieChartDataValues: [],
            jpc: null,
            showSlice: 0,
        }
    },
    computed: {},
    mounted: function (){
        this.jpc = document.getElementById("pie-chart-canvas");

        this.setPieData();
        this.initializePiechart();
    },
    methods: {
        setPieData: function () {
            let that = this;
            this.pieChartData = JSON.parse(this.jpc.dataset.work);

            this.pieChartData.forEach(function (item) {
                that.pieChartDataLabels.push(item.label);
                that.pieChartDataValues.push(item.value);
            });
        },
        initializePiechart: function () {
            let that = this;
            this.pieChart = new Chart(this.jpc, {
                type: 'pie',
                data: {
                    labels: that.pieChartDataLabels,
                    datasets: [{
                        data: that.pieChartDataValues,
                        backgroundColor: [
                            '#F8C0D1',
                            '#BBF6CD',
                            '#C1BCDD',
                            '#FFDD97',
                            '#B5EFFE',
                            '#F76F98',
                            '#5DF48A',
                            '#7663DD',
                            '#FFBE3D',
                            '#54DCFF'
                        ],
                        borderWidth: 0
                    }]
                },
                options: {
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        enabled: false,
                    },
                    onHover: function(e, a) {
                        if (a[0] !== undefined) {
                            that.showSlice = a[0]['_index'];
                        }
                    },
                }
            });
        }
    },
    created: function () {
    },
    destroyed: function () {
    }
});