Vue.component('notes', {
    data() {
        return {
            showModal: false,
        }
    },
    mixins: [
        Mixins.submitForm
    ],
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        submit() {
            let that = this;

            this.$validator.validateAll().then(result => {
                let analytics = that.$el.dataset.analytics;

                if (result) {
                    if (!this.hasCaptcha || this.captchaExecuted) {
                        that.submitForm(event.target, analytics);
                    } else {
                        this.$refs.recaptcha.execute();
                    }
                }
            });
        }
    }
});