Vue.component('file-input', {
    data() {
        return {
            value: '',
            preview: '',
        }
    },
    props: {
        currentImage: String,
    },
    methods: {
        handleFileChange(e) {
            let that = this;
            let reader  = new FileReader();

            this.value = e.target.files[0];

            reader.addEventListener("load", function () {
                that.preview = reader.result;
            }, false);

            if (this.value) {
                reader.readAsDataURL(this.value);
            }
        },
        resetFile() {
            const input = this.$refs.fileInput;
            input.type = 'text';
            input.type = 'file';
            this.value = '';
            this.preview = this.currentImage;
        }
    },
    mounted() {
        if (this.presetSrc !== '') {
            this.preview = this.currentImage;
        }
    }
});