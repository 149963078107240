var store = new Vuex.Store({
    modules:{
        Layout: Layout.store,
        Base: Base.store,
    },
    state: {
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    },
    actions: {
        toggleMenu({commit}, value) {
            commit('toggleMenu', value)
        },
    },
    getters: {
        isMobile() {
            return store.state.isMobile;
        },
        menuExpanded() {
            return store.state.menuExpanded;
        }
    },
    mutations: {
        toggleMenu(state, value) {
            store.state.menuExpanded = value;
        }
    }
});