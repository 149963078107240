Vue.component('slide-list', {
    store,
    data() {
        return {
            showMe: false,
        };
    },
    props: {
        slideId: String,
        name: String,
        browsingSlides: Array,
        searchQuery: String,
    },
    watch: {
        searchQuery: function(newQuery) {
            this.showMe = false;

            if ( newQuery !== '' && this.name.toLowerCase().indexOf(newQuery.toLowerCase()) !== -1 ) {
                this.browsingSlides.push({slideID: parseInt(this.slideId)});
                this.showMe = true;
                this.$emit('show-parent');
            }
        },
        browsingSlides: function() {
            this.showMe = false;

            for(let i = 0; i < this.browsingSlides.length; i++) {
                if (this.browsingSlides[i].slideID === parseInt(this.slideId)) {
                    this.showMe = true;
                }
            }
        }
    },
    computed: {
        bookmarksArray() {
            return this.$store.getters['Layout/bookmarks'];
        },
    },
    methods: {
        slideListClass() {
            let result = [];

            if (this.showMe) {
                result.push('navigation-section-index__list--active');
            }

            return result;
        },
        openSlideList() {
            this.openMe();
            this.openParent();
        },
        openMe() {
            this.showMe = false;

            for(let i = 0; i < this.browsingSlides.length; i++) {
                if (this.browsingSlides[i].slideID === parseInt(this.slideId)) {
                    this.showMe = true;
                }
            }

            if (!this.showMe) this.browsingSlides.push({slideID: parseInt(this.slideId)});
        },
        openParent() {
            this.$emit('show-parent');
        }
    },
});