Vue.component('browse', {
    store,
    data: function () {
        return {
            visible: true
        }
    },
    computed: {},
    methods: {
        arrowNavigation: function() {
            document.onkeydown = checkKey;

            function checkKey(e) {
                if (typeof(e) === "undefined") { e = window.event; }

                else if (e.keyCode == '37') {
                    document.querySelector('.browse__btn--left').click();
                }
                else if (e.keyCode == '39') {
                    document.querySelector('.browse__btn--right').click();
                }
            }
        }
    },
    mounted: function (){
        let that = this;
        let timeout;

        timeout = setTimeout(function() {
            that.visible = false;
        }, 3000);

        document.addEventListener("mousemove", function() {
            showMe();
        });

        document.addEventListener("touchstart", function() {
            showMe();
        });

        function showMe() {
            clearTimeout(timeout);
            that.visible = true;

            timeout = setTimeout(function() {
                that.visible = false;
            }, 3000);
        }

        this.arrowNavigation();
    },
    created: function () {
    },
    destroyed: function () {
    }
});