Vue.component('slide-list-item', {
    store,
    props: {
        slideId: String,
        name: String,
        browsingSlides: Array,
        searchQuery: String,
    },
    computed: {
        bookmarksArray() {
            return this.$store.getters['Layout/bookmarks']
        },
    },
    watch: {
        searchQuery: function(newQuery) {
            if ( newQuery !== '' && this.name.toLowerCase().indexOf(newQuery.toLowerCase()) !== -1 ) {
                this.browsingSlides.push({slideID: parseInt(this.slideId)});

                this.$emit('show-parent');
            }
            else if ( newQuery === '' ) {
                this.openBookmarked();
            }
        },
    },
    methods: {
        isBookmarked() {
            return this.bookmarksArray.findIndex(item => item.id === parseInt(this.slideId)) !== -1;
        },
        toggleBookmark(bookmark, url) {
            this.$store.dispatch("Layout/toggleBookmark", {
                bookmark: bookmark,
                url: url,
            });
        },
        browseSlide() {
            let close = false;

            for(let i = 0; i < this.browsingSlides.length; i++) {
                if (this.browsingSlides[i].slideID === parseInt(this.slideId)) {
                    close = true;

                    this.browsingSlides.splice(i, 1);
                }
            }

            if (!close) this.browsingSlides.push({ slideID: parseInt(this.slideId)});
        },
        slideArrowClass() {
            let result = [];

            for(let i = 0; i < this.browsingSlides.length; i++) {
                if (this.browsingSlides[i].slideID === parseInt(this.slideId)) result.push('navigation-section-index-list-item__arrow--active');
            }

            return result;
        },
        openParent() {
            this.$emit('show-parent');
        },
        openBookmarked() {
            if (this.bookmarksArray.findIndex(item => item.id === parseInt(this.slideId)) !== -1) {
                this.$emit('show-parent');
            }
        }
    },
    mounted() {
        this.openBookmarked();
    }
});