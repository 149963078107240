Vue.component('email-fields', {
    data: function() {
        return {
            emailFields: [
                '',
            ],
        }
    },
    methods: {
        updateFields: function(fieldValue, index) {
            this.emailFields[index] = fieldValue;
            if (fieldValue !== '' && index + 1 === this.emailFields.length) {
                this.emailFields.push('');
            }
            else if (fieldValue === '' && index + 1 !== this.emailFields.length) {
                this.emailFields.splice(index, 1);
            }
        },
    },
})